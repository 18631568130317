import React from "react";
import logo from "../assets/logowhite2.svg";

const SectionTwo = () => {
  return (
    <div className="bgimageBlue2 mb-0">
      <div className="d-flex justify-content-center pt-5">
        <div className="mt-lg-5">
          {" "}
          <h4>We are delivering Happiness to Thousands</h4>
          <p>We are the right partner you need to meet your logistic needs.</p>
          <div className="mt-5 d-flex justify-content-center ">
            {" "}
            {/* <button className="go">Let's Go!</button> */}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end logo">
        <img src={logo} alt="" />
      </div>
    </div>
  );
};

export default SectionTwo;
