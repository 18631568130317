import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import TermsAndConditionsSection from '../components/TermsAndConditions';

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <TermsAndConditionsSection />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
