import axios from "axios"
import React, { useRef, useState } from "react"
import HubspotForm from "react-hubspot-form"
const Contact = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [message, setMessage] = useState("")
  const form = useRef()
  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   // Call the HubSpot form submission function
  //   window.hbspt.forms.create({
  //     portalId: "43547646",
  //     formId: "60ba0055-4ab1-42cb-8f91-b6d8f341a3e9",
  //     css: "",
  //     target: "#hubspotForm", // Target element where the form will be rendered
  //     onFormSubmitted: () => console.log("Form submitted successfully"),

  //     inlineMessage: "sumitted",
  //     formData: {
  //       // Map form data to HubSpot fields
  //       email: email,
  //       firstname: name,
  //       lastname: "", // You may need to adjust this based on your HubSpot form fields
  //       company: companyName,
  //       message: message,
  //     },
  //   })
  // }
  const handleSubmit = async (e) => {
    e.preventDefault() // prevent form submit default behavior
    console.log("hubs", email, name, message)
    if (!name || !email) return // if an input field is empty, don't submit the form
    const hubspot_response = await submit_hubspot_form(email, name, message)
    console.log(hubspot_response, "hubspot_response") // make sure it succeeded!
  }

  const submit_hubspot_form = async (email, name, message) => {
    console.log("hubs", email, name, message)
    const portalId = "43547646" // example portal ID (not real)
    const formGuid = "60ba0055-4ab1-42cb-8f91-b6d8f341a3e9" // example form GUID (not real)
    const config = {
      // important!
      headers: {
        "Content-Type": "application/json",
      },
    }

    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        TICKET: {
          subject: "Subject",
        },
        fields: [
          {
            name: "firstname",
            value: name,
          },
          {
            name: "lastname",
            value: name,
          },
          {
            name: "email",
            value: email,
          },
          // {
          //   name: "Company Name",
          //   value: companyName,
          // },
          {
            name: "message",
            value: message,
          },
        ],
      },
      config
    )
    return response
  }
  console.log("first")
  return (
    <div className="bgContact px-lg-5 p-3 mb-5">
      <div className="container p-lg-5 ">
        <div className=" align-items-center row">
          <div className="col-md-6 px-lg-5">
            <h6>Get In Touch</h6>
            <p>
              Looking for more information, or do you want to try to use any of
              our services but don't know how to get started? We're here to
              help! Submit your information and an iTranxit representative will
              follow up with you as soon as possible. Have a simple question?
              Check out FAQ page.
            </p>
          </div>
          <div className="col-md-6 form">
            <HubspotForm
              portalId="43547646"
              formId="60ba0055-4ab1-42cb-8f91-b6d8f341a3e9"
              onSubmit={() => console.log("Submit!")}
              onReady={(form) => console.log("Form ready!")}
              loading={<div>Loading...</div>}
            />
          </div>
          {/* <form className="col-md-6 form" onSubmit={handleSubmit}>
            <label>Name</label>
            <input value={name} onChange={(e) => setName(e.target.value)} />
            <label>Email Address</label>
            <input value={email} onChange={(e) => setEmail(e.target.value)} />
            <label>Company Name</label>
            <input
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <label>Your Message</label>
            <textarea
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button type="submit" value="Send">
              Send Message
            </button>
          </form> */}
        </div>
      </div>
    </div>
  )
}

export default Contact
