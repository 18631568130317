import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicySection = () => {
  return (
    <div className="privacy">
      <div className="bgContact px-lg-5 p-3 mb-5">
        <div className="container py-lg-4 ">
          <h6 className="text-center">Privacy Policy </h6>
        </div>
      </div>
      <div className="container mb-5">
        The Internet is an amazing tool. It can change how we live, and we're
        starting to see that potential today. With only a few mouse clicks, you
        can follow the news, look up facts, buy goods and services, and
        communicate with others from around the world. It's important to 3XG
        iTranxit LTD to help our customers retain their privacy when they take
        advantage of all the Internet has to offer. We believe your business is
        no one else's. Your privacy is important to you and to us. So we'll
        protect the information you share with us. To protect your privacy, 3XG
        iTranxit LTD follows different principles in accordance with worldwide
        practices for customer privacy and data protection. We won’t sell or
        give away your name, mail address, phone number, email address or any
        other information to anyone. We’ll use state-of-the-art security
        measures to protect your information from unauthorized users.
        <h6 className="mt-4">NOTICE</h6>
        We will ask you when we need information that personally identifies you
        (personal information) or allows us to contact you. Generally, this
        information is requested when you download iTranxit, or create an order.
        We use your Personal Information for the the following purposes: To make
        the site easier for you to use by not having to enter information more
        than once. verify you, your identity, that the data provided is credible
        and prevent fraud; provide customer support to you; To help us create
        content most relevant to you. To alert you to product upgrades, special
        offers, updated information and other new services from 3XG ITRANXIT
        LTD.
        <h6 className="mt-4">CONSENT</h6>
        If you choose not to register or provide personal information, you can
        still use most of YOUR WEBSITE ADDRESS. But you will not be able to
        access areas that require registration. If you decide to register, you
        will be able to select the kinds of information you want to receive from
        us by subscribing to various services, like our electronic newsletters.
        Suppose you do not want us to communicate with you about other offers
        regarding 3XG iTranxit LTD products, programs, events, or services by
        email, postal mail, or telephone. In that case, you may select the
        option stating that you do not wish to receive marketing messages from
        3XG ITRANXIT LTD. 3XG iTranxit LTD occasionally allows other companies
        to offer our registered customers information about their products and
        services using postal mail only. If you do not want to receive these
        offers, you may select the option stating that you do not wish to
        receive marketing materials from third parties.
        <h6 className="mt-4">ACCESS</h6>
        We will provide you with the means to ensure your personal information
        is correct and current. You may review and update this information.
        There, you can: View and edit the personal information you have already
        given us. Tell us whether you want us to send you marketing information
        or whether you want third parties to send you their offers by postal
        mail. Sign up for electronic newsletters about our services and
        products. Register. Once you register, you won't need to do it again.
        Your information stays with you wherever you go on{' '}
        <Link to={'www.itranxit.com'}>www.itranxit.com</Link>
        <h6 className="mt-4">SECURITY</h6>
        3XG iTranxit LTD has taken strong measures to protect your personal
        information’s security and ensure that your choices for its intended use
        are honored. We take strong precautions to protect your data from loss,
        misuse, unauthorized access or disclosure, alteration, or destruction.
        We guarantee your Online transactions to be 100% safe and secure. When
        you place orders or access your personal account information, you're
        utilizing secure server software SSL, which encrypts your personal
        information before it's sent over the Internet. SSL is one of the safest
        encryption technologies available. In the event of unauthorized use of
        your credit card, you must notify your credit card provider in
        accordance with its reporting rules and procedures. 3XG iTranxit LTD
        strictly protects the security of your personal information and honors
        your choices for its intended use. We protect your data from loss,
        misuse, unauthorized access or disclosure, alteration, or destruction.
        Your personal information is never shared outside the company without
        your permission, except under the conditions explained above. Inside the
        company, data is stored in password-controlled servers with limited
        access. Your information may be stored and processed in Nigeria or any
        other country where 3XG ITRANXIT LTD, its subsidiaries, affiliates or
        agents are located. You also have a significant role in protecting your
        information. No one can see or edit your personal information without
        knowing your username and password, so do not share these with others.
        <h6 className="mt-4">ENFORCEMENT</h6>
        If for some reason you believe 3XG iTranxit LTD has not adhered to these
        principles, please notify us by email at{' '}
        <Link to={'mailto:help@itranxit.com'}>help@itranxit.com</Link> and we
        will do our best to determine and correct the problem promptly. Be
        certain the words Privacy Policy are in the Subject line.
        <h6 className="mt-4">WHAT DO WE DO WITH THE INFORMATION YOU SHARE</h6>
        When you join us, you provide us with your contact information and email
        address. We use this information to send you updates about your order,
        questionnaires to measure your satisfaction with our service, and
        announcements about new and exciting services we offer. We ask for your
        billing address when you order from us. We use this information only to
        bill you for the product(s) you order at that time. For your
        convenience, we don’t save billing information in case you want to order
        from us again, but we don't use this information again without your
        permission. We occasionally hire other companies to provide limited
        services on our behalf, including packaging, mailing, and delivering
        orders , answering customer questions about products or service. We will
        only provide those companies with the information they need to deliver
        the service, and they are prohibited from using that information for any
        other purpose. 3XG iTranxit LTD will disclose your personal information,
        without notice, only if required to do so by law or in the good faith
        belief that such action is necessary to:
        <ol type="a">
          <li>
            conform to the edicts of the law or comply with legal process served
            on 3XG iTranxit LTD or the site;{' '}
          </li>
          <li>
            protect and defend the rights or property of 3XG iTranxit LTD and
            its family of Websites, and,
          </li>
          <li>
            act in urgent circumstances to protect the personal safety of users
            of 3XG ITRANXIT LTD, its Websites, or the public.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PrivacyPolicySection;
