import { Icon } from "@iconify/react"
import { motion } from "framer-motion"
import React, { useRef, useState } from "react"
import { Link } from "react-router-dom"
import logo from "../assets/logo.svg"
import MobileNav from "./MobileNav"

const navItems = [
  {
    name: "About us",
    link: "/about",
  },
  {
    name: "Contact us",
    link: "/contact",
  },
  {
    name: "FAQ's",
    link: "/#faqs",
  },
]

const getStartedItems = [
  {
    name: "Drivers",
    // content: "Lorem ipsum dolor sit amet, consectetur ",
    link: "https://driver.itranxit.com/",
  },
  {
    name: "Customers",
    link: "https://customer.itranxit.com/",
    // content: "Lorem ipsum dolor sit amet, consectetur ",
  },
  {
    name: "Fleet Manager",
    link: "https://fleet.itranxit.com/",
    // content: "Lorem ipsum dolor sit amet, consectetur ",
  },
]

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const containerRef = useRef(null)

  const toggleGetStartedMenu = () => setIsMenuOpen(!isMenuOpen)

  return (
    <div className="container mb-lg-5 mt-lg-5 mt-4 mb-3">
      <div className="row mx-lg-5">
        <div className="col-md-12 d-flex align-items-center justify-content-between">
          <Link to={"/"}>
            <img src={logo} alt="" />
          </Link>
          <div className="navBox">
            {navItems.map(({ name, link }, index) => (
              <a key={index} href={link} className="nav-items">
                {name}
              </a>
            ))}
          </div>
          <div className="navRight">
            <a
              className="place-order"
              href="https://customer.itranxit.com/"
              target={"_blank"}
              rel="noreferrer"
            >
              Get an Estimate
            </a>

            <div>
              <div className="get-started-btn" onClick={toggleGetStartedMenu}>
                Get Started&nbsp;&nbsp;
                <motion.div
                  initial="close"
                  animate={isMenuOpen ? "open" : "close"}
                  variants={{
                    open: { rotate: "180deg" },
                    close: { rotate: "0deg" },
                  }}
                >
                  <div ref={containerRef}>
                    <Icon icon="ep:arrow-down-bold" className="mb-1" />
                  </div>
                </motion.div>
              </div>

              {isMenuOpen && (
                <motion.div
                  initial={{ display: "none", y: -30 }}
                  animate={{ display: "block", y: 0 }}
                  className="position-absolute"
                >
                  <div className="getStartedLinks">
                    {getStartedItems.map(({ name, link, content }, index) => (
                      <>
                        <a
                          key={index}
                          target="_blank"
                          href={link}
                          className="getStartedItem"
                          rel="noreferrer"
                        >
                          {/* <div > */}
                          <div className="title">{name}</div>
                          <div className="content">{content}</div>
                          {/* </div>{" "} */}
                        </a>
                      </>
                    ))}
                  </div>
                </motion.div>
              )}
            </div>
          </div>
        </div>
      </div>
      <MobileNav />
    </div>
  )
}

export default Navbar
