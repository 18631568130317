import React, { useState } from 'react';
import logo from '../assets/logowhite.svg';
import { AnimatePresence, motion } from 'framer-motion';

const navItems = [
  {
    name: 'About us',
    link: '/about',
  },
  {
    name: 'Contact us',
    link: '/contact',
  },
  {
    name: "FAQ's",
    link: '/#faqs',
  },
  {
    name: 'Support',
    link: 'mailto:help@itranxit.com',
  },
];

const itemVariants = {
  closed: {
    opacity: 0,
  },
  open: { opacity: 1 },
};

const MobileNav = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <>
      {' '}
      <nav className="mobile ">
        <div
          className={` burger ${toggleMenu ? 'open' : ''}`}
          style={{ transform: 'translate(0px, 0px)', opacity: 1 }}
          onClick={toggleNav}
        >
          <span>&nbsp;</span>
          <span className="dif">&nbsp;</span>
          <span>&nbsp;</span>
        </div>

        <AnimatePresence>
          {toggleMenu && (
            <>
              <motion.aside
                initial={{ width: 0 }}
                animate={{
                  width: '100%',
                }}
                exit={{
                  width: 0,
                }}
              >
                <motion.div
                  className={`links ${toggleMenu ? 'open' : ''}`}
                  initial="closed"
                  animate="open"
                  exit="closed"
                >
                  <a className="navbar-brand text-white" href="/">
                    <img src={logo} alt="logo" />
                  </a>
                  <div className="container pl-5 pb-4 pt-5">
                    <p className="pt-4">NAVIGATIONS</p>

                    <ul
                      style={{ listStyle: 'none' }}
                      className="pl-0 text-white"
                    >
                      {navItems.map(({ name, link }, index) => (
                        <motion.li key={index}>
                          <motion.a
                            href={link}
                            variants={itemVariants}
                            className="nav-items text-white"
                            onClick={() => setToggleMenu(false)}
                          >
                            {name}
                          </motion.a>
                        </motion.li>
                      ))}
                    </ul>

                    <p className="mt-5">GET STARTED</p>

                    <ul
                      style={{ listStyle: 'none' }}
                      className="pl-0 text-white"
                    >
                      <li>
                        <motion.a
                          className="place-order"
                          href={'https://customer.itranxit.com/'}
                          target={'_blank'}
                          rel="noreferrer"
                          variants={itemVariants}
                        >
                          Place Order
                        </motion.a>
                      </li>{' '}
                      <li>
                        <motion.a
                          href={'https://driver.itranxit.com/'}
                          className="nav-items text-white"
                          variants={itemVariants}
                          target="_blank"
                        >
                          Drivers
                        </motion.a>
                      </li>
                      <li>
                        <motion.a
                          href={'https://customer.itranxit.com/'}
                          className="nav-items text-white"
                          target="_blank"
                          variants={itemVariants}
                        >
                          Customers
                        </motion.a>
                      </li>
                      <li>
                        <motion.a
                          href={'https://fleet.itranxit.com/'}
                          className="nav-items text-white"
                          target="_blank"
                          variants={itemVariants}
                        >
                          Fleet Manager
                        </motion.a>
                      </li>
                    </ul>
                  </div>
                </motion.div>
              </motion.aside>
            </>
          )}
        </AnimatePresence>
      </nav>
    </>
  );
};

export default MobileNav;
