import React from 'react';
import About from '../components/About';
import Banner from '../components/Banner';
import FaqsAndFooter from '../components/FaqsAndFooter';
import Gallery from '../components/Gallery';
import Navbar from '../components/Navbar';
import SectionFour from '../components/SectionFour';
import SectionOne from '../components/SectionOne';
// import SectionThree from "../components/SectionThree";
import SectionTwo from '../components/SectionTwo';

const App = () => {
  return (
    <>
      <Navbar />
      <Banner />
      <About />
      <SectionOne />
      <Gallery />
      <SectionTwo />
      {/* <SectionThree /> */}
      <SectionFour />
      <FaqsAndFooter />
    </>
  );
};

export default App;
