import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AboutSection from "../components/AboutSection";
// import TeamSection from "../components/TeamSection";

const About = () => {
  return (
    <>
      <Navbar />
      <AboutSection />
      {/* <TeamSection /> */}
      <Footer />
    </>
  );
};

export default About;
