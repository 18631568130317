import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './pages/index';
import About from './pages/about';
import Contact from './pages/contact';
import PrivacyPolicy from './pages/privacy-poilcy';
import TermsAndConditions from './pages/terms-and-conditions';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="*" element={<Index />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
