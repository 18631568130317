import React, { useState, useRef } from 'react';
import image from '../assets/macbook.svg';
import logo from '../assets/logo-white.svg';
import { motion, useScroll, useTransform } from 'framer-motion';

const data = [
  {
    title: 'Fast',
    desc: 'We offer quick pickup and delivery by giving you access to the bestin-class courier service.',
  },
  {
    title: 'Efficient',
    desc: 'Our service commitment to you is hassle-free delivery. Whether you are a large corporation or a small business our innovative delivery solution will efficiently meet your logistic needs.',
  },
  {
    title: 'Customer Friendly',
    desc: 'Your happiness is our primary goal, we are available 24/7 to receive your enquiries, or complaints.',
  },
  {
    title: 'Ease of Access',
    desc: 'There is no need to sweat it, we have made it easy and simple for you to access the right logistics service with just few clicks',
  },
  {
    title: 'Flexibility',
    desc: 'Our platform offers you the opportunity to select the mode of transport and the choice of vehicle that is suitable to achieve your delivery needs.',
  },
];

const About = () => {
  const [isActive, setIsActive] = useState(0);

  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref });

  function useParallax(value, distance) {
    return useTransform(value, [0, 1], [-distance, distance]);
  }

  const y = useParallax(scrollYProgress, 30);
  return (
    <>
      <div className="bgimageBlue" id="about">
        <div className="container-fluid pt-5">
          <div className="mb-5 mt-lg-5">
            <h4>
              Everything you need to run logistics for personal and business.
            </h4>
            <div className="subtitle mt-4">
              We take the burden of logistics off you with our platform by
              helping you find a choice professional independent courier service
              within minutes to get your goods moving fast.
            </div>
          </div>

          <div className="row" ref={ref}>
            <div className="col-md-5 d-flex align-items-center ps-lg-5 order-2 order-lg-1 mb-5  ">
              <div className="ps-lg-5 mb-5">
                {data?.map((item, index) => {
                  return (
                    <div
                      className={`sidebox cursor-pointer p-4 ${
                        isActive === index ? 'active' : ''
                      }`}
                      key={index}
                      onClick={() => setIsActive(index)}
                    >
                      <h6>{item.title}</h6>
                      <p className="mb-0">{item.desc}</p>
                    </div>
                  );
                })}
              </div>
            </div>{' '}
            <div className="col-md-7 order-1 order-lg-2">
              <motion.div style={{ y }}>
                {' '}
                <img src={image} alt="devImage" className="featureImg" />
              </motion.div>
            </div>
          </div>
          <div className="row position-relative">
            <div className=" d-flex justify-content-center position-absolute bottom-0 mb-5">
              <a
                className="go mt-20 mt-5 px-5"
                href="https://customer.itranxit.com/"
                target={'_blank'}
                rel="noreferrer"
                style={{ textDecoration: 'none', zIndex: '10' }}
              >
                Get an Estimate
              </a>
            </div>
            <div className="col-md-12 d-flex justify-content-end position-absolute bottom-0">
              <img src={logo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
