import React from "react";
import Faqs from "./Faqs";
import Footer from "./Footer";

const FaqsAndFooter = () => {
  return (
    <div className="faqs-and-footer">
      <Faqs />
      <Footer />
    </div>
  );
};

export default FaqsAndFooter;
