import React from "react";
import image1 from "../assets/image1.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";
import image4 from "../assets/image4_.png";

const Gallery = () => {
  return (
    <div className="gallery container-fluid px-0 ">
      <div className="row">
        <div className="col-md-3 position-relative">
          <div className="imagedown">
            <img src={image1} alt="" />
          </div>
        </div>
        <div className="col-md-3 position-relative">
          <img src={image2} alt="" />{" "}
        </div>
        <div className="col-md-3 position-relative">
          <div className="imagedown">
            <img src={image3} alt="" />
          </div>
        </div>
        <div className="col-md-3 position-relative">
          <img src={image4} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
