import React from 'react';
// import logo from "../assets/transistor.svg";

const Banner = () => {
  return (
    <>
      <div className="bannerImg"></div>
      <div className="d-flex justify-content-center mb-lg-5 mt-lg-5 mt-1 mb-4">
        <a
          className="get-started-btn px-4 z-index-1"
          href="https://customer.itranxit.com/"
          target={'_blank'}
          rel="noreferrer"
          style={{ textDecoration: 'none', zIndex: '10', color: 'white' }}
        >
          Start Shipping{' '}
        </a>
      </div>
      {/* <p className=" trusted mt-lg-5 mt-3">Trusted by these six companies so far</p>
      <div className="d-flex justify-content-center flex-wrap align-items-center trustedImg mb-4">
        <img src={logo} alt="" className="me-lg-3 m-2" />
        <img src={logo} alt="" className="me-lg-3 m-2" />
        <img src={logo} alt="" className="me-lg-3 m-2" />
        <img src={logo} alt="" className="me-lg-3 m-2" />
        <img src={logo} alt="" className="me-lg-3 m-2" />
        <img src={logo} alt=""  className=" m-2"/>
      </div> */}
    </>
  );
};

export default Banner;
