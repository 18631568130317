import React from 'react';
import happiness from '../assets/happiness.svg';
import vision from '../assets/vision.svg';
import certificate from '../assets/certificate.svg';

const AboutSection = () => {
  return (
    <div className="aboutSection">
      <div className="bgContact px-lg-5 p-3 mb-5">
        <div className="container py-lg-4 ">
          <h6 className="text-center">About Us</h6>
        </div>
      </div>
      <div className="happiness d-lg-flex mx-auto justify-content-center gap-5 align-items-center mb-5">
        <img src={happiness} alt="happiness" className="featureImg" />
        <div className="happinessBox">
          <h6 className="mb-3">We are delivering Happiness to Thousands.</h6>
          <p>
            iTranxit is a logistics technology company that creates value by
            giving access to customers/businesses to be able to make the choice
            of logistics service that is cost-effective with a high level of
            operational efficiency based on their business models. Our central
            goal is to help make transportation and delivery easier and more
            affordable for our customers.
          </p>{' '}
        </div>
      </div>
      <div className="bgContact pl-lg-5 pl-3 mb-5">
        <div className="container py-lg-4 ">
          <div className=" align-items-center row">
            <div className="col-md-6 px-lg-5">
              <h6>Our Vision</h6>
              <p>
                Our vision is to build the largest logistics technology hub for
                Africa and Africans. We are creating a connected loop for both
                intra and inter-logistics within African regions.
              </p>
            </div>
            <div className="col-md-6 d-lg-block d-none">
              <img src={vision} alt="vision" className="featureImg" />
            </div>
          </div>
        </div>
      </div>

      <h6 className="text-center mt-5 pt-5 mb-lg-5 mb-3">Our Core Values</h6>

      <div class="container px-lg-5 p-3 mb-lg-5 mb-3">
        <div class="row gap-lg-3 gap-4">
          <div class="col-sm">
            <div className="value">
              <img
                src={certificate}
                alt="certificate"
                className="certificateImg"
              />

              <h6>Reliability & Efficiency</h6>
              <p>
                We are dedicated to providing reliable and efficient logistics
                solutions that empower businesses to operate seamlessly and
                enhance customer satisfaction.
              </p>
            </div>
          </div>
          <div class="col-sm">
            <div className="value">
              <img
                src={certificate}
                alt="certificate"
                className="certificateImg"
              />

              <h6>Customer-first Approach</h6>
              <p>
                Our customers' success is at the heart of everything we do. We
                prioritize their needs, offering tailored solutions and
                exceptional support to exceed their expectations
              </p>
            </div>
          </div>
          <div class="col-sm">
            <div className="value">
              <img
                src={certificate}
                alt="certificate"
                className="certificateImg"
              />

              <h6>Commitment to Excellence</h6>
              <p>
                We are passionate about revolutionizing logistics technology and
                committed to going above and beyond to create meaningful impact.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
