import React from 'react';

const TermsAndConditionsSection = () => {
  return (
    <div className="privacy">
      <div className="bgContact px-lg-5 p-3 mb-5">
        <div className="container py-lg-4 ">
          <h6 className="text-center">Terms And Conditions </h6>
        </div>
      </div>
      <div className="container mb-5">
        <p className="p3">
          Welcome to www.itranxit.com (hereinafter referred to as the
          &ldquo;Website&rdquo;, &ldquo;We,&rdquo; &ldquo;Us,&rdquo; or
          &ldquo;Our&rdquo;), owned and operated by 3XG iTranxit limited
          <strong>&nbsp;</strong>(hereinafter referred to as &ldquo;the
          Company&rdquo;) with its registered office located at Ifako Gbagada,
          Lagos Nigeria. The Website is offered to You conditioned on Your
          acceptance without modification of the Terms, Conditions, and notices
          contained herein (the &quot;Terms&quot;).
          <span className="Apple-converted-space">&nbsp;</span>
        </p>

        <p className="p5 mt-4">
          <strong>INTRODUCTION</strong>
        </p>

        <p className="p5">
          Our Website is a Platform (hereinafter referred to as
          &ldquo;Platform&rdquo;) where users market, offer and provide logistic
          solutions. The Users of the Website shall be referred to as
          &ldquo;You,&rdquo; &ldquo;Your,&rdquo; or &ldquo;Users.&rdquo;
        </p>
        <p className="p6">
          <br />
        </p>
        <p className="p5">
          By clicking on the &quot;Accept&quot; button at the end of the
          Agreement acceptance form, Users agree to be bound by the Terms and
          Conditions of this Agreement. Please read this entire Agreement
          carefully before accepting its Terms. When You undertake any activity
          on the Website, You agree to accept these Terms and Conditions.
        </p>

        <p className="p5">
          In using this Website, You are deemed to have read and agreed to the
          following Terms and Conditions set forth herein. Any incidental
          documents and links mentioned shall be accepted jointly with these
          Terms. You agree to use the Website only in strict interpretation and
          acceptance of these Terms, and any actions or commitments made without
          regard to these Terms shall be at Your own risk. These Terms and
          Conditions form part of the Agreement between the Users and Us.
          Accessing this Website and/or undertaking to perform a Service
          provided by Us indicates Your understanding, agreement to, and
          acceptance of the disclaimer notice and the full Terms and Conditions
          contained herein.
        </p>
        <p className="p7">
          <br />
        </p>

        <p className="p9">
          <strong>ELIGIBILITY OF THE USER</strong>
        </p>

        <p className="p9">
          You may use the Service only if You are at least eighteen (18) years
          of age and can form a binding contract with Us, and only in compliance
          with this Agreement and all applicable local, state, national, and
          international laws, rules, and regulations.
          <span className="Apple-converted-space">&nbsp;</span>
        </p>

        <p className="p5">
          Unauthorized Users are strictly prohibited from accessing or
          attempting to access, directly or indirectly, the Platform. Any
          unauthorized use is strictly forbidden and constitutes a violation of
          applicable state and local laws.
        </p>

        <p className="p5">
          Our Website may, in its sole discretion, refuse to offer access to or
          use of the Platform to any person or entity and change its eligibility
          criteria at any time. This provision is void where prohibited by law,
          and the right to access the Website is revoked in such jurisdictions.
        </p>

        <p className="p5">
          <strong>SERVICES OFFERED BY THE PLATFORM</strong>
        </p>

        <p className="p5">
          We provide the Users with a Platform to access logistics or delivery
          service offered by third-party logistics company.
        </p>

        <p className="p5">
          <strong>YOU AGREE AND CONFIRM</strong>
        </p>

        <p className="p5">
          You will use the Services provided by Our Platform, its affiliates,
          and contracted companies for lawful purposes only and comply with all
          applicable laws and regulations while using the Platform.
        </p>

        <p className="p5">
          You will provide authentic and true information in all instances where
          such information is requested of You. We reserve the right to confirm
          and validate the information and other details provided by You at any
          point in time. If, upon confirmation, Your details are found not to be
          true (wholly or partly), We have the right in Our sole discretion to
          reject the registration and debar You from using the Services of Our
          Platform and/or other affiliated websites without prior intimation
          whatsoever.
        </p>

        <p className="p5">
          You are accessing the Services available on this Website and
          transacting at Your sole risk. You are using Your best and prudent
          judgment before entering into any dealings through this Platform.
        </p>

        <p className="p5">
          It is possible that the other Users (including
          unauthorized/unregistered users or &quot;hackers&quot;) may post or
          transmit offensive or obscene materials on the Platform. You may be
          involuntarily exposed to such offensive or obscene materials. It also
          is possible for others to obtain personal information about You due to
          Your use of the Platform, and the recipient may use such information
          to harass or injure You. We disapprove of such unauthorized uses.
          Still, by using the Platform, You acknowledge and agree that We are
          not responsible for the use of any personal information that You
          publicly disclose or share with others on the Platform. Please
          carefully select the type of information that You publicly disclose or
          share with others on the Platform.
        </p>

        <p className="p5">
          You agree not to post or transmit any unlawful, threatening, abusive,
          libelous, defamatory, obscene, vulgar, pornographic, profane, or
          indecent information or description/image/text/graphic of any kind,
          including, without limitation, any transmissions constituting or
          encouraging conduct that would constitute a criminal offense, give
          rise to civil liability or otherwise violate any local, state,
          national, or international law.
        </p>

        <p className="p5">
          You agree not to post or transmit any information, software, or other
          material which violates or infringes the rights of others, including
          material that is an invasion of privacy or publicity rights or which
          is protected by copyright, trademark, or other proprietary rights, or
          derivative works with respect thereto, without first obtaining
          permission from the owner or right holder.
        </p>

        <p className="p5">
          You agree not to alter, damage, or delete any Content or other
          communications that are not Your own Content or to otherwise interfere
          with the ability of others to access Our Platform.
        </p>

        <p className="p5">
          You agree to indemnify and keep indemnified the Company from all
          claims/losses (including advocates&rsquo; fees for
          defending/prosecuting any case) that may arise against the Company due
          to acts/omissions on the part of the User.
        </p>

        <p className="p5">
          <strong>
            WARRANTIES, REPRESENTATION, AND UNDERTAKINGS OF USER
            <span className="Apple-converted-space">&nbsp;</span>
          </strong>
        </p>

        <p className="p5">
          The User warrants and represents that all obligations narrated under
          this Agreement are legal, valid, binding, and enforceable in law
          against the User.
        </p>

        <p className="p5">
          The User agrees that there are no proceedings pending against the User
          which may adversely affect its ability to perform and meet the
          obligations under this Agreement.
        </p>

        <p className="p5">
          The User agrees that it shall always ensure compliance with all the
          requirements applicable to its business and for the purposes of this
          Agreement, including but not limited to intellectual property rights,
          value-added tax, excise, import duties, etc. It further declares and
          confirms that it has paid and shall continue discharging all its
          obligations to statutory authorities.
        </p>

        <p className="p5">
          The User agrees that it has adequate rights under relevant laws,
          including but not limited to various intellectual property
          legislation(s) to enter into this Agreement with the Company and
          perform the obligations contained herein and that it has not
          violated/infringed any intellectual property rights of any third
          party.
        </p>

        <p className="p5">
          The User agrees that appropriate disclaimers and Terms of use on the
          Company&rsquo;s Website shall be placed by the Company.
        </p>

        <p className="p5">
          <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
        </p>

        <p className="p5">
          The User expressly authorizes the Company to use its
          trademarks/copyrights/designs/logos and other intellectual property
          owned and/or licensed by it for the purpose of reproduction on the
          Platform and at such other places as the Company may deem necessary.
          It is expressly agreed and clarified that, except as specified agreed
          in this Agreement, each Party should retain all rights, title, and
          interest in their respective trademarks and logos and that nothing
          contained in this Agreement, nor the use of the trademarks/logos in
          the publicity, advertising, promotional or other material in relation
          to the Services shall be construed as giving any Party any right,
          title or interest of any nature whatsoever to any of the other
          Party&rsquo;s trademarks and/or logos.
        </p>

        <p className="p5">
          The Company&rsquo;s Website and other Platforms, and the information
          and materials it contains, are the property of the Company and its
          licensors and are protected from unauthorized copying and
          dissemination by copyright law, trademark law, international
          conventions, and other intellectual property laws. All the
          Company&rsquo;s product names and logos are trademarks or registered
          trademarks. Nothing contained on the Company&rsquo;s Website should be
          interpreted as granting, by implication, estoppel, or otherwise, any
          license or right to use the Company&rsquo;s Website or any materials
          displayed on the Company&rsquo;s Website through the use of framing or
          otherwise, except: (a) as expressly permitted by these Terms and
          Conditions; or (b) with the prior written consent of the Company. The
          User shall not attempt to override or circumvent any of the usage
          rules or restrictions on the Website.
        </p>

        <p className="p5">
          Except as otherwise expressly granted to You in writing, We do not
          grant You any other express or implied right or license to the
          Services, Our Content, or Our intellectual property rights.
        </p>

        <p className="p5">
          Proprietary Rights. Subject to the limited rights expressly granted in
          this Agreement, the Company reserves all rights, title, and interest
          in and to the Service, including all related intellectual property
          rights. No rights are granted to the User in this Agreement other than
          as expressly outlined in this Agreement.
        </p>

        <p className="p5">
          <strong>DATA</strong>
        </p>

        <p className="p5">
          &quot;Data&quot; means any and all identifiable information about
          Users and their affiliates generated or collected by the Company or
          the User, including but not limited to the User&rsquo;s name, email
          addresses, Services availed, phone numbers, and the User&rsquo;s
          preferences and tendencies. The User agrees that it will only use the
          Data in complying with its obligations in this Agreement.
          <span className="Apple-converted-space">&nbsp;</span>
        </p>

        <p className="p5">
          The User represents warrants and covenants that it will not resell or
          otherwise disclose any Data to any third party, in whole or in part,
          for any purpose whatsoever.
        </p>

        <p className="p5">
          The User acknowledges that the Services may contain information
          designated as confidential by the Company and that You shall not
          disclose such information without the Company&rsquo;s prior written
          consent.
        </p>

        <p className="p5">
          By submitting Content, the User hereby irrevocably grants Us a
          perpetual, irrevocable, nonexclusive, royalty-free right to use the
          Content for any purpose, including API partnerships with third parties
          and in any media existing now or in the future. The User irrevocably
          waives and causes to be waived any claims and assertions of moral
          rights or attribution with respect to the User&rsquo;s Content brought
          against Us by any third-party services or their users.
        </p>

        <p className="p5">
          We may obtain business addresses, phone numbers, and other contact
          information from third-party vendors who obtain their Data from public
          sources. We have no control over and make no representation or
          endorsement regarding the accuracy, relevancy, copyright compliance,
          legality, completeness, timeliness, or quality of any products,
          services, advertisements, and other Content appearing in or linked to
          the Services.
        </p>

        <p className="p5">
          <strong>RELATIONSHIP</strong>
        </p>

        <p className="p5">
          Nothing in this Agreement will be construed as creating a relationship
          of partnership, joint venture, agency, or employment between the
          Parties. The Company shall not be responsible for the acts or
          omissions of the User, and the User shall not represent the Company;
          neither does it have any power or authority to speak for, represent,
          bind, or assume any obligation on behalf of the Company.
        </p>

        <p className="p5">
          <strong>
            INDEMNITY<span className="Apple-converted-space">&nbsp;</span>
          </strong>
        </p>

        <p className="p5">
          The User indemnifies and shall hold indemnified the Company, its
          partners, officers, employees, representatives, and agents from and
          against all losses, damages, claims, suits, legal proceedings, and
          otherwise howsoever arising from or in connection with any claim,
          including but not limited to claims for any infringement of any
          intellectual property rights or any other rights of any third party or
          of law, concerning quality, quantity and any claim in relation to the
          User&rsquo;s products, the breach of any of the User&rsquo;s
          warranties, representations or undertakings or in relation to the
          non-fulfillment of any of its obligations under this Agreement or
          Terms of use of Company&rsquo;s Website or any obligations arising out
          of the User infringing any applicable laws, regulations including but
          not limited to intellectual property rights, or taxes, etc. This
          clause shall survive the termination or expiration of this Agreement.
        </p>

        <p className="p5">
          <strong>EXPRESS RELEASE</strong>
        </p>

        <p className="p5">
          You expressly hereby release and waive all claims against the Company
          and its subsidiaries, affiliates, officers, agents, licensors,
          co-branders or other partners, and employees from any and all
          liability for claims, damages (actual and/or consequential), costs and
          expenses (including litigation costs and attorney&rsquo;s fees) of
          every kind and nature, arising from or in any way related to Your use
          of the Company&rsquo;s Website. You understand that any fact relating
          to any matter covered by this release may be found to be other than
          now believed to be true, and You accept and assume the risk of such
          possible differences in fact. In addition, You expressly waive and
          relinquish any rights and benefits You may have under any other state
          or federal statute or common law principle of similar effect to the
          fullest extent permitted by law.
        </p>

        <p className="p5">
          <strong>LIMITATION OF LIABILITY</strong>
        </p>

        <p className="p5">
          The User expressly agrees that the Company shall under no
          circumstances be liable or responsible for any loss, injury, or damage
          to the User or any other Party, whomsoever arising on account of any
          transaction under this Agreement.
          <span className="Apple-converted-space">&nbsp;</span>
        </p>

        <p className="p5">
          The User agrees and acknowledges that it shall be solely liable for
          any claims, damages, or allegations arising out of the Services
          through the Platform and shall hold the Company harmless and
          indemnified against all such claims and damages. Further, the Company
          shall not be liable for any claims or damages arising out of any
          negligence, misconduct, or misrepresentation by the User or any of its
          representatives.<span className="Apple-converted-space">&nbsp;</span>
        </p>

        <p className="p5">
          The Company under no circumstances shall be liable to the User for
          loss and/or anticipated loss of profits, or any direct or indirect,
          incidental, consequential, special or exemplary damages arising from
          the subject matter of this Agreement, regardless of the type of claim
          and even if the User has been advised of the possibility of such
          damages, such as, but not limited to loss of revenue or anticipated
          profits or loss of business, unless such loss or damages is proven by
          the User to have been deliberately caused by the Company.
        </p>

        <p className="p5">
          <strong>TERMINATION</strong>
        </p>

        <p className="p5">
          This Agreement may be terminated by the Company if the User commits a
          material breach of any representation, obligation, covenant, warranty
          or term of this Agreement and the same is not cured within 30 days
          after written notice given by the Company, if a petition for
          insolvency is filed against the User or if the User is in infringement
          of third-party rights, including intellectual property rights.
        </p>

        <p className="p5">
          This Agreement may be terminated without reason by either Party after
          serving upon the other a written notice of thirty (30) days. The
          Agreement shall stand terminated after the expiry of such period.
          <span className="Apple-converted-space">&nbsp;</span>
        </p>

        <p className="p5">
          <strong>EFFECTS OF TERMINATION</strong>
        </p>

        <p className="p5">
          In the event of termination/expiry of this Agreement, the Company
          shall remove and discontinue the Services provided to the User on its
          Platform immediately.
        </p>

        <p className="p5">
          The Company shall not be liable for any loss or damages (direct,
          indirect or inconsequential) incurred by the User by virtue of
          termination of this Agreement.
        </p>

        <p className="p5">
          During the period under notice, both the Parties shall be bound to
          perform their obligations incurred under this Agreement, and this
          sub-clause shall survive the termination of this Agreement.
        </p>

        <p className="p5">
          <strong>GOVERNING LAW AND DISPUTE RESOLUTION:</strong>
        </p>

        <p className="p5">
          This Agreement shall be construed and enforced in accordance with the
          laws of the Federal Republic of Nigeria without regard to the Company
          or the Website of its conflict of law provisions or the User&rsquo;s
          state or country of residence.
        </p>

        <p className="p5">
          The User submits to the exclusive jurisdiction of the courts of the
          Federal Republic of Nigeria for the enforcement of this Agreement or
          any arbitration award or decision arising from this Agreement.
        </p>

        <p className="p5">
          If there is a dispute between the User and other Users, the User
          understands and agrees that the Company is under no obligation with
          respect thereto, and the User, to the fullest extent permitted by law,
          hereby releases the Company and its affiliates, and each of their
          respective officers, directors, employees, service providers,
          affiliates, agents, and successors from, and agrees to indemnify each
          of the foregoing for any losses incurred in connection with any and
          all claims, demands and damages (actual and consequential) of every
          kind or nature, known or unknown, suspected and unsuspected, disclosed
          and undisclosed, arising out of or in any way related to such
          disputes.
        </p>

        <p className="p5">
          The User agrees that if it cannot resolve its disputes with other
          Users, then the Company has the right to remove the User from the
          Website and terminate this Agreement.
        </p>

        <p className="p5">
          <strong>DISCLAIMER</strong>
        </p>

        <p className="p5">
          To the fullest extent permitted by law, the Company and its
          affiliates, and each of its respective officers, directors, members,
          employees, and agents disclaim all warranties, express or implied, in
          connection with this Agreement, the Website and any use thereof,
          including, without limitation, the implied warranties of
          merchantability, fitness for a particular purpose and
          non-infringement. The Company makes no warranties or representations
          about the accuracy or completeness of the Website&apos;s Content or
          any other Websites linked to the Website. It assumes no liability or
          responsibility for any (a) errors, mistakes, or inaccuracies of
          Content and materials, (b) personal injury or property damage of any
          nature whatsoever resulting from the User&apos;s access to and use of
          the Website, (c) any unauthorized access to or use of the
          Company&apos;s servers and/or any and all personal information and/or
          financial information stored therein, (d) any interruption or
          cessation of transmission to or from the Website, (e) any bugs,
          viruses, trojan horses, or the like which may be transmitted to or
          through the Website by any third party, and/or (f) any errors or
          omissions in any Content and materials or for any loss or damage of
          any kind incurred as a result of the use of any Content posted,
          transmitted, or otherwise made available via the Website. The Company
          does not guarantee the privacy, security, or authenticity of any
          information transmitted over or stored in any system connected to the
          internet or mobile devices.&nbsp;
        </p>

        <p className="p5">
          <strong>
            NOTICES<span className="Apple-converted-space">&nbsp;</span>
          </strong>
        </p>

        <p className="p5">
          Unless explicitly stated otherwise, any notices given to the Company
          shall be emailed to&nbsp;info@itranx.com at an address Ifako Gbagada,
          Lagos. Any notices given to the User shall be to the email address
          provided by the User to the Company at the time of listing (or as such
          information may be updated via the Website by the User from time to
          time) or at the mailing address provided by the User to the Company.
        </p>

        <p className="p5">
          Any notice, demand, or request with respect to this Agreement shall be
          in writing. It shall be effective only if it is delivered by email,
          personal service, by air courier with receipt of delivery, or mailed,
          certified mail, return receipt requested, postage prepaid, to the
          address set forth above. Such communications shall be effective when
          they are received by the addressee, but if sent by certified mail in
          the manner set forth above, they shall be effective five (5) days
          after being deposited in the mail.
          <span className="Apple-converted-space">&nbsp;&nbsp;</span>Any Party
          may change its address for such communications by giving notice to the
          other Party in conformity with this section.
        </p>

        <p className="p5">
          <strong>
            AMENDMENT<span className="Apple-converted-space">&nbsp;</span>
          </strong>
        </p>

        <p className="p5">
          The Company may, at any time at its sole discretion, modify this
          Agreement from time to time. Any such changes will (i) be reflected on
          the Website, (ii) be effective thirty (30) calendar days after being
          so posted on the Website, (iii) not apply retroactively, and (iv) not
          apply to any disputes arising prior to the effective date of such
          change. The Company shall also post the amended Agreement to the
          address of the User. The User agrees to be bound to any such changes
          and understands the importance of regularly reviewing this Agreement
          as updated on the Website to keep the User&rsquo;s listing and contact
          information current.
        </p>

        <p className="p5">
          Notwithstanding anything to the contrary herein, the Company reserves
          the right to, at any time and from time to time, change, alter,
          modify, or discontinue the Website (or any part thereof) with or
          without notice. The User agrees that the Company shall have no
          liability to the User or to any third party for any change,
          alteration, modification, suspension, discontinuance, or amendment of
          the Company&rsquo;s Website.
        </p>

        <p className="p5 mb-2">
          <strong>FORCE MAJEURE</strong>
        </p>

        <p className="p5">
          Except with regard to payment obligations, either Party shall be
          excused from delays in performing or from failing to perform its
          obligations under this contract to the extent the delays or failures
          resulting from causes beyond the reasonable control of the Party,
          including, but not limited to: failures or default of third-party
          software, Users, or products; acts of God or of a public enemy;
          foreign governmental actions; strikes; communications, network
          connection, or utility interruption or failure; fire; flood; epidemic;
          or freight embargoes.
        </p>

        <p className="p9 mb-2">
          <span className="s1">
            <strong>CONTACT US:</strong>
          </span>
        </p>
        <p className="p9">
          For any further clarification of Our Terms and Conditions, please
          write to us at{' '}
          <a href="mailto:info@itranxit.com">info@itranxit.com</a>
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditionsSection;
