import React from 'react';

const data = [
  {
    question: 'What is the iTranxit App?',
    answer:
      'The iTranxit app is an efficient on-demand app built to solve your logistics needs and be your delivery companion on time. It handles all your requests ranging from automated deliveries to pick-up requests, payment for transactions and lots more. Use it on the go, at your convenience, from anywhere in the world on your own terms.',
  },
  {
    question: 'What happens when a shipment gets damaged or missing?',
    answer:
      'This rarely happens but in the event that it does, iTranxit will make full refund to the customer based on the value declared of item(s) missing or damaged',
  },
  {
    question:
      'Will my parcel be delivered to my final destination anywhere in Nigeria',
    answer:
      'Yes. We will deliver anywhere in Nigeria, so long as your preferred address is on google Map. As we constantly strive to serve you better, please note that we are rapidly expanding to cover all locations within Nigeria.',
  },
  {
    question: 'What is the timeline for pick up?',
    answer: 'Pick up is real time but also based on availability of a rider. ',
  },
  {
    question: 'Do I need to set up my wallet instantly?',
    answer:
      'Anyone with a fully updated user profile on the iTranxit App can make deliveries within Lagos, Nigeria. For E-commerce merchants, you will need to use your regular account to order.',
  },
  {
    question: 'Can we expect more wallet features?',
    answer:
      'Yes, it is. However, pick-up requests will only be assigned to a rider between 6am- 6pm. Any request after 6pm will be put on the priority queue and assigned the next day.',
  },
  {
    question: 'What happens if the rider cancels my request?',
    answer: 'Pick up will be assigned to another available rider. ',
  },
  {
    question: 'How do I make payment?',
    answer:
      'After you place an order using the website or Mobile app, you will get a Payment link through which you can make payments. The link offers you the option of making secured payments through your funded wallet on the App, a thirdparty payment platform (Pay stack/Flutter wave), or your Bank Card. ',
  },
  {
    question: 'Do you ship all kind of items?',
    answer:
      'Yes, excluding items prohibited by law enforcement agencies. The size of your item is no barrier as special arrangements are available for supersized items',
  },
  // {
  //   question: "How do I track my delivery?",
  //   answer:
  //     "You can track it by using your mobile app to know where the rider is using live location on the map",
  // },
];

const Faqs = () => {
  return (
    <div id="faqs" className="faqs container px-lg-5 pb-5 ">
      <div className="heading">
        {' '}
        <h4>Frequently asked questions</h4>
        <p>
          If you can't find what you're looking for, email our support team and someone will get back to you
        </p>
      </div>
      <div className="row faq-items  ">
        {data?.map((item, index) => {
          return (
            <div className="col-md-4 mb-4 " key={index}>
              <h6>{item.question}</h6>
              <p className="mb-0">{item.answer}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faqs;
