import React from "react";
import uparrow from "../assets/uparrow.svg";
import downarrow from "../assets/downarrow.svg";
import track from "../assets/track.svg";
import wallet from "../assets/wallet.svg";
import support from "../assets/support.svg";

const data = [
  {
    icon: track,
    title: "Track",
    subtitle: "Track your parcels from any where at anytime",
    desc: "We use real time google map to track our drivers when on a ride. This is to enable the customer monitors his/her delivery.",
    color: "#2f9ce9",
  },
  {
    icon: wallet,
    title: "Wallet",
    subtitle:
      "Our wallets are available to keep your money so you dont get stranded.",
    desc: "We have an inbuilt wallet on our platform, which enables our customers to transfer from their personal account to the wallet. This allows easy payment of deliveries on our platform",
  },
  {
    icon: support,
    title: "Support",
    subtitle: "Our support team is here to assist 24/7 days a week!",
    desc: "We have an integrated live chat on both web application and mobile application that allows you to connect with our customer support team easily",
  },
];
const SectionOne = () => {
  return (
    <div className="position-relative">
      {" "}
      <div className="uparrow">
        <img src={uparrow} alt="" />
      </div>
      <div className="sectionone container px-lg-5 py-lg-5 py-3">
        <div className="mb-5 mt-5">
          <h4>Simplify everyday errands and trips.</h4>
          <div className="subtitle mt-4">
            Move your goods or items across different states and regions with
            our integrated logistics technology service that offers you the
            platform to be flexible and fast in achieving your transportation
            needs.
          </div>
        </div>

        <div className="row pt-lg-5">
          {data?.map((item, index) => {
            return (
              <div className="col-md-4 mb-3 " key={index}>
                <img src={item.icon} alt="" />
                <h6 style={{ color: item?.color }}> {item.title}</h6>
                <h5>{item.subtitle}</h5>
                <p>{item.desc}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="downarrow">
        <img src={downarrow} alt="" />
      </div>
    </div>
  );
};

export default SectionOne;
